<!-- Desktop -->
<div class="toolbar__controls" *ngIf="isDesktop">
  <app-donation-button class="toolbar__button" *ngIf="isDonateAvailable"></app-donation-button>
  <mzima-client-button
    (buttonClick)="openShare()"
    color="secondary"
    class="toolbar__button"
    [data-qa]="'share'"
  >
    <span>{{ 'app.share' | translate }}</span>
    <mat-icon icon svgIcon="share"></mat-icon>
  </mzima-client-button>
</div>

<!-- Smaller screens -->
<ng-container *ngIf="!isDesktop">
  <div class="menu__footer-container">
    <div class="menu__footer">
      <app-donation-button
        class="menu__footer__button"
        *ngIf="isDonateAvailable"
      ></app-donation-button>
      <mzima-client-button
        fill="outline"
        color="transparent-style-1"
        (buttonClick)="openShare()"
        class="menu__footer__button"
      >
        {{ 'app.share' | translate }}
        <mat-icon icon svgIcon="share"></mat-icon>
      </mzima-client-button>
    </div>
  </div>
</ng-container>
