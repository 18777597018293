<nav class="sidebar__menu" id="sidebar-menu">
  <ng-container *ngFor="let item of menu">
    <ng-container *ngIf="!item.hidden && (!item.adminGuard || isHost)">
      <a
        [id]="item.ref"
        class="sidebar-menu-button"
        [data-qa]="'btn-' + item.icon"
        [attr.data-onboard-id]="'sidebar-btn-' + item.icon"
        [routerLink]="item.router && createRouterLink(item.router)"
        routerLinkActive="sidebar-menu-button--active"
        (click)="item.router && registerPage($event, item.router, item.label)"
      >
        <mat-icon class="sidebar-menu-button__icon" [svgIcon]="item.icon"></mat-icon>
        <span class="sidebar-menu-button__title">
          {{ item.label | translate }}
        </span>
      </a>
    </ng-container>
  </ng-container>
</nav>
