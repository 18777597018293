<strong mat-dialog-title class="title" [data-qa]="'task-modal-title'"
  >{{ 'user_profile.nav.delete_contact.title' | translate }}
  {{ 'user_profile.nav.delete_contact.title_subject.' + this.data.contactType | translate }}</strong
>

<mat-dialog-content class="delete-contact-modal">
  <div class="form-row">
    <div class="instructions">
      {{ 'user_profile.nav.delete_contact.instructions' | translate }}
    </div>
  </div>
  <div class="form-row">
    <div class="contact-method" *ngFor="let contact of this.contacts; let i = index">
      <mat-slide-toggle [(ngModel)]="notificationSwitches[i]" [data-qa]="'contact-notify-toggle'">
        {{ contact.contact }}
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row"></div>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <mzima-client-button
    fill="outline"
    color="secondary"
    (buttonClick)="cancel()"
    [data-qa]="'survey-task-cancel'"
  >
    {{ 'user_profile.nav.delete_contact.button.cancel' | translate }}
  </mzima-client-button>
  <mzima-client-button type="submit" (buttonClick)="submit()" [data-qa]="'survey-task-add'">
    {{ 'user_profile.nav.delete_contact.button.delete' | translate }}
  </mzima-client-button>
</div>
