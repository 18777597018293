<!-- TODO: One html code for Desktop & mobile here (without breaking existing functionality for desktop). Is this possible? -->

<!-- Desktop -->
<div class="sidebar__menu" *ngIf="isDesktop">
  <ng-container *ngFor="let item of menu">
    <ng-container *ngIf="item.forDesktop && (!item.adminGuard || isHost)">
      <button
        [id]="item.ref"
        (click)="item.action && item.action()"
        class="sidebar-menu-button"
        [data-qa]="'btn-' + item.icon"
        [attr.data-onboard-id]="'sidebar-btn-' + item.icon"
      >
        <mat-icon class="sidebar-menu-button__icon" [svgIcon]="item.icon"></mat-icon>
        <span [attr.data-qa]="'auth-btn-label'" class="sidebar-menu-button__title">{{
          item.label | translate
        }}</span>
      </button>
    </ng-container>
  </ng-container>
</div>

<!-- Smaller screens -->
<div class="menu__body" *ngIf="!isDesktop">
  <div class="mobile-menu">
    <ng-container *ngFor="let item of menu">
      <hr *ngIf="item.separator" />
      <a
        *ngIf="item.visible"
        class="mobile-menu-button"
        [data-qa]="'btn-' + item.icon"
        (click)="item.action && item.action()"
      >
        <mat-icon class="mobile-menu-button__icon" [svgIcon]="item.icon"></mat-icon>
        <span class="mobile-menu-button__title">
          {{ item.label | translate }}
        </span>
      </a>
    </ng-container>
    <hr *ngIf="isLoggedIn || canRegister" />
    <a class="mobile-menu-button" href="https://www.ushahidi.com/privacy-policy" target="_blank">
      {{ 'app.privacy_policy' | translate }}
    </a>
    <a class="mobile-menu-button" href="https://www.ushahidi.com/terms-of-service" target="_blank">
      {{ 'app.terms_and_conditions' | translate }}
    </a>
  </div>
</div>
