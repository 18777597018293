<div class="form-head-panel">
  <div
    class="form-head-panel__inner form-head-panel__inner--actions"
    *ngIf="isShowActions || (isDesktop$ | async)"
  >
    <h1 *ngIf="isDesktop$ | async" [data-qa]="'title'">{{ settingsTitle | translate }}</h1>
    <ng-container *ngIf="isShowActions">
      <mzima-client-button
        color="danger"
        class="delete-button"
        (buttonClick)="deleteEmit()"
        [data-qa]="'btn-settings-delete'"
        [disabled]="!selectedItem.length"
      >
        <mat-icon icon svgIcon="delete" class="button-icon"></mat-icon>
        <span class="button-name">{{ 'app.delete' | translate }}</span>
      </mzima-client-button>
      <ng-content></ng-content>
    </ng-container>
  </div>
  <div
    class="form-head-panel__inner"
    *ngIf="isShowCreation"
    [ngClass]="{ 'form-head-panel__inner--single': !isShowActionsButton }"
  >
    <mzima-client-button
      fill="outline"
      color="secondary"
      [isActive]="isShowActions"
      *ngIf="isShowActionsButton"
      (buttonClick)="showActions()"
      [data-qa]="'btn-settings-action'"
    >
      {{ 'settings.bulk_actions' | translate }}
    </mzima-client-button>
    <mzima-client-button [routerLink]="['create']" [data-qa]="'btn-settings-create'">
      <span>{{ newButtonTitle | translate }}</span>
      <mat-icon icon svgIcon="plus"></mat-icon>
    </mzima-client-button>
  </div>

  <!-- isShowTranslation -->
  <div class="form-head-panel__inner" *ngIf="isShowTranslation">
    <div class="translate-container">
      <mat-form-field appearance="outline" class="feed-page__control">
        <mat-select
          name="language"
          disableOptionCentering
          [(ngModel)]="selectedLanguage"
          [placeholder]="'app.select_language' | translate"
          (selectionChange)="selectLanguageEmit($event)"
          [data-qa]="'app.select_language'"
        >
          <mat-option
            *ngFor="let language of languages"
            [value]="language"
            [data-qa]="'lang-' + language"
          >
            {{ language?.name }} {{ language?.code === defaultLanguage?.code ? '(default)' : '' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mzima-client-button [data-qa]="'btn-settings-translation'" (buttonClick)="showLanguagesEmit()">
      {{ newButtonTitle | translate }}
      <mat-icon icon svgIcon="plus"></mat-icon>
    </mzima-client-button>
  </div>
  <!-- END isShowTranslation -->
</div>
